<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      램 장터
      <div class="search">
        <div class="input_box_n">
          <input
            type="text"
            name=""
            v-model="searchText"
            placeholder="상품을 검색해주세요."
            @keydown.enter="goSearch"
          />
        </div>
        <router-link to="" @click.native="goSearch"
          ><img src="@/assets/images/sub/alarm_search_n_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <!---->
      <div class="cate_wrap">
        <div class="cate_left">
          <router-link to="/market_main_list"
            ><img src="@/assets/images/sub/cate_menu_ico.png" alt=""
          /></router-link>
        </div>
        <div class="cate_right">
          <!-- -->
          <GoodsCategory
            :category="categories"
            :id="id"
            ref="goodsCategory"
            @getGoodsCategory="getGoodsCategory"
          />
          <!-- -->
        </div>
      </div>
      <!---->
      <div class="cate_view">
        <div class="step_1">
          <div
            class="step_1_box"
            v-for="(cate, index) in copyCategories"
            :key="cate.id"
          >
            <span
              class="step_1_span"
              data-level="3"
              :data-id="cate.id"
              @click="moreCategory(index, $event)"
              >{{ cate.title }}</span
            ><router-link to="" class="step_1_a"
              ><img src="@/assets/images/sub/my_down_w_ico.png" alt=""
            /></router-link>
            <template v-for="level2 in lowCategorise2">
              <div
                v-if="parseInt(level2.parent_id) === parseInt(cate.id)"
                class="step_2_box step"
                :id="`${id}_${level2.parent_id}_${cate.id}`"
                :key="`${level2.id}_2`"
              >
                <span
                  class="step_2_span"
                  data-level="4"
                  :data-id="level2.id"
                  @click="moreCategory(level2.id, $event)"
                  >{{ level2.title }}</span
                ><router-link to="" class="step_2_a"
                  ><img src="@/assets/images/sub/my_down_w_ico.png" alt=""
                /></router-link>
                <div
                  class="step_3"
                  v-for="level3 in lowCategorise3"
                  :key="`${level3.id}_3`"
                >
                  <div
                    v-if="parseInt(level3.parent_id) === parseInt(level2.id)"
                    :id="`${id}_${level3.parent_id}_${level2.id}`"
                    class="step_3_box"
                  >
                    <span
                      class="step_3_span"
                      data-level="5"
                      :data-id="level3.id"
                      @click="moreCategory(level3.id, $event)"
                      >{{ level3.title }} </span
                    ><router-link to="" class="step_3_a"
                      ><img src="@/assets/images/sub/my_down_ico.png" alt=""
                    /></router-link>
                    <template v-for="level4 in lowCategorise4">
                      <div
                        v-if="
                          parseInt(level4.parent_id) === parseInt(level3.id)
                        "
                        class="step_4_box"
                        :key="`${level4.id}_4`"
                        @click="goMarketGoodsList(level4.id)"
                      >
                        <router-link to="">{{ level4.title }}</router-link>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
import GoodsCategory from "@/components/shop/menu/GoodsCategory";
export default {
  data() {
    return {
      first: true,
      id: null,
      level: null,
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      searchText: "",
    };
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();
  },
  components: {
    MainMenu,
    GoodsCategory,
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("shop", [
      "result",
      "msg",
      "totalPage",
      "categories",
      "copyCategories",
      "lowCategorise2",
      "lowCategorise3",
      "lowCategorise4",
    ]),
  },
  methods: {
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      this.$router.push(`/search/goods_search?search=${this.searchText}`);
    },
    async goMarketGoodsList(id) {
      await this.$store.dispatch("shop/getGoodsList", {
        id: id,
        level: "5",
        sort: "newly",
        page: 0,
        perpage: 1,
        categoryNew: true,
      });
      // if (parseInt(this.totalPage) <= 0) {
      //   this.$toast.default("존재하는 상품이 없습니다.");
      //   return false;
      // }
      this.$router.push(`/market_goods_list/5/${id}`);
    },
    getGoodsCategory(id) {
      this.id = id;
      // const el = document.querySelectorAll(".review.swiper-slide");
      // let target = null;
      // [...el].map((e) => {
      //   if (e.dataset.id === id) {
      //     target = e;
      //   }
      // });

      let cpCateIdx = this.copyCategories.findIndex(
        (x) => parseInt(x.id) === parseInt(this.id)
      );

      // console.log(target.dataset.swiperSlideIndex);
      // console.log(cpCateIdx);

      if (!this.first) {
        // document
        //   .querySelectorAll(".step_1_span")
        //   [target.dataset.swiperSlideIndex].click();
        document.querySelectorAll(".step_1_span")[cpCateIdx].click();
      }
      this.first = false;
    },
    async moreCategory(index, event) {
      this.id = event.target.dataset.id; //id 세팅
      this.level = parseInt(event.target.dataset.level); // 레벨 세팅
      const modify = 2; //레벨 조정 변수

      //레벨이 1 카테고리라면 상단에 카테고리 메뉴 이동
      if (parseInt(this.level - modify) <= 1) {
        let orgIdx = this.categories.findIndex(
          (x) => parseInt(x.id) === parseInt(this.copyCategories[index].id)
        );
        this.$refs.goodsCategory.goSlide(orgIdx);
      }

      //클릭된 대상
      const target = event.target.closest("div");

      //현재 클릭된 레벨의 카테고리 초기화
      await this.$store.dispatch("shop/clearLowCategory", parseInt(this.level));

      //닫기 아이콘 초기화
      const stepBox = document.querySelectorAll(`.${target.classList[0]}`);
      [...stepBox].map((step) => {
        step.querySelector("img").style.transform = "rotate(0deg)";
      });

      //대상이 open 클래스를 가지있지 않으면 open 부여후 데이터 가져오고 바로 아래 레벨 카테고리 엘레먼트 block처리
      if (!target.classList.contains("open")) {
        target.classList.add("open");
        target.querySelector("img").style.transform = "rotate(180deg)";
        await this.$store.dispatch("shop/getGoodsLowCategory", {
          level: this.level,
          id: this.id,
          is_sort: !event.isTrusted,
        });

        if (!this.result) {
          this.$toast.default(this.msg);
          target.classList.remove("open");
          target.querySelector("img").style.transform = "rotate(0deg)";
        }
      } else {
        target.classList.remove("open");
        target.querySelector("img").style.transform = "rotate(0deg)";

        //타겟 보다 하나 아래 카테고리 최기화
        // this.$store.dispatch("shop/clearLowCategory", parseInt(this.level) + 1);
      }

      [...document.querySelectorAll(`.${target.classList[0]}`)].map((step) => {
        if (step !== target) step.classList.remove("open");
      });
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getCategories();
    },
    async getCategories() {
      await this.$store.dispatch("shop/getCategories");
      this.$refs.goodsCategory.reflash();
    },
    getMenu(id) {
      if (parseInt(id) == 1) {
        this.$router.push(`/market_main_list`).catch(() => {});

        return false;
      }
      if (parseInt(id) == 3) {
        return false;
      }
    },
  },
};
</script>

<style></style>
